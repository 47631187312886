import React from "react"
import styled from "styled-components"

const items = [
    {
        id: '1',
        url: 'https://orange7.ubanbok.com/player/?static=true',
        guideVideo: 'https://universe-repeat-public.s3.ap-northeast-2.amazonaws.com/%EC%88%98%EB%8A%A5.mp4',
        label: '수능',
        guideLabel: '1등급 가이드',
        playerLabel: '1등급 솔루션',
    },
    {
        id: '2',
        url: 'https://orange7.ubanbok.com/player/?lectureId=64&ownershipId=205&classId=95',
        guideVideo: 'https://universe-repeat-public.s3.ap-northeast-2.amazonaws.com/%EB%82%B4%EC%8B%A0.mp4',
        label: '내신',
        guideLabel: '100점 가이드',
        playerLabel: '100점 솔루션',
    },
]

const Cont = styled.div`
    background-color: #363342;
    font-family: "regular";
    min-height: 100vh;
`

const Head = styled.div`
    height: 90px;
    padding: 0px 30px 0px 30px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #484456;
`

const Title = styled.span`
    display: block;
    font-size: 28px;
    line-height: 46px;
    color: white;
    font-weight: bold;
`

const Body = styled.div`
    padding: 30px;
`

const ItemCont = styled.span`
    height: 90px;
    padding: 0px 28px 0px 28px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #7F8193;

    &:first-of-type {
        border-top: 1px solid #7F8193;
    }

    &:hover {
        background-color: #2D2A39;
    }
`

const ItemText = styled.span`
    font-size: 20px;
    color: white;
    font-weight: bold;
`

const ItemButton = styled.a`
    padding: 0 4px 0 4px;
    line-height: 40px;
    border-radius: 4px;
    background-color: #ff5c00;
    color: white;
    font-weight: bold;

    margin-left: 8px;
        
    text-decoration: none;
    &:visited,&:hover,&:active {
        text-decoration: none;
    }
`

const Item = ({id, url, guideVideo, label, guideLabel, playerLabel}) => {
    return (
        <ItemCont key={id}>
            <ItemText>{label}</ItemText>
            <ItemButton style={{marginLeft: '24px'}} href={guideVideo}>{guideLabel}</ItemButton>
            <ItemButton href={url}>{playerLabel}</ItemButton>
        </ItemCont>
    )
}

const SelectPlayerExample = () => {
    return (
        <Cont>
            <Head>
                <Title>1등급 비법 공개</Title>
            </Head>
            <Body>
                {items.map((item) => {
                    return (
                        <Item {...item}/>
                    )
                })}
            </Body>
        </Cont>
    )
}

export default SelectPlayerExample
